import React from 'react'
import './logo.css'
function Logo(props) {
    return (
        <div onClick={()=>props.onClick()} {...props} className='d-flex justify-content-center' >
            {/* <span className="wideFont sectionTitle MainlogoText link" style={{}} >
                PXDP
            </span> */}
            <img className="img-fluid custom-img"  src={require('../assets/images/logo.png')}  />
        </div>
    )
}

export default Logo
