import React from 'react'
import { sectionLinks } from '../resources/globalConstants'
import { sectionLinkStyles } from '../styles/CommonStyles'
import Logo from './Logo'
function Footer() {
    return (
        <div
        className='row align-items-center'
            style={{
                borderTop: '1px solid #CDCBC5',
                marginTop:40,
                paddingTop:20,
            }}
        >
            {/* <div className="row" style={{ flex: 1 }}>
                {sectionLinks.map((item) => {
                    return (
                        <span key={item} className="textDescriptionBold col-auto" style={{marginRight:0,fontSize:16, ...sectionLinkStyles}}>
                            {item}
                        </span>
                    )
                })}
            </div> */}

            {/* <div className='col-auto' style={{  alignItems: 'center', justifyContent: 'center' }}> */}
            {/* <div className=" col-3 align-items-center" style={{width:'100%',  alignItems: 'center', justifyContent: 'center' }}> */}
                {/* <Logo /> */}
                {/* <img  width="10" height="50"  src={require('../assets/images/logo.png')}  /> */}
            {/* </div> */}

            {/* <div className="row " style={{ flex: 1, justifyContent: 'flex-end' }}>
                {['Privacy Policy'].map((item) => {
                    return (
                        <span key={item} className="textDescriptionBold col-auto" style={{ ...sectionLinkStyles }}>
                            {item}
                        </span>
                    )
                })}
            </div> */}
        </div>
    )
}

export default Footer
