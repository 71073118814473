import React, { useEffect } from 'react'
import '../styles/_variables.sass'
import { commonBorderStyle } from '../styles/CommonStyles.js'
import RoundHoloButton from './RoundHoloButton'
import Logo from './Logo'
import ActiveSectionIndicator from './ActiveSectionIndicator'
let stickClass = ''

export default function Header(props) {
    let cs = {
        marginRight: 50,
        fontSize: 16,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column'
    }

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    function scrollToSection(ref) {
        if (ref) {
            ref.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    let activityIndicator = (
        <div style={{ width: 0, height: 0 }}>
            {/* <ActiveSectionIndicator /> */}
        </div>
    )
    let commonDiv = (
        <>
            <span onClick={() => scrollToSection(props.aboutRef)} className="textDescriptionBold link" style={cs}>
                About
                {activityIndicator}
            </span>
            <span onClick={() => scrollToSection(props.servicesRef)} className="textDescriptionBold link" style={cs}>
                Services
                {activityIndicator}
            </span>

            {/* <span onClick={() => scrollToSection(props.servicesRef)} className="textDescriptionBold link" style={cs}>
                Our Work
                {activityIndicator}
            </span> */}

            {/* <RoundHoloButton onClick={() => scrollToSection(props.servicesRef)} /> */}
        </>
    )

    if(!props.scrollRoot){
        if(props.isSticky){
            stickClass = 'is-sticky'
        } else if(stickClass == 'is-sticky') {
            stickClass='is-sticky-invisible'
        } else {
            stickClass='is-sticky-out'
        }
    } else {
        stickClass=''
    }
    return (
        <div id="header" className={stickClass}>
            <div className="col-12 col-sm-2">
                <Logo onClick={()=>handleScrollToTop()} />
            </div>
            <div className="d-flex d-sm-none col-12 ">
                <div className="mt-3 mt-sm-0 " style={{ justifyContent: 'center', display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    {commonDiv}
                </div>
            </div>

            <div className="d-none d-sm-flex col ">
                <div className="mt-3 mt-sm-0 " style={{ justifyContent: 'flex-end', display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    {commonDiv}
                </div>
            </div>
        </div>
    )
}
