import React, { useEffect, useRef, useState } from 'react'
import DateDisplay from '../components/DateDisplay'
import Header from '../components/Header'
import SectionTitleBox from '../components/SectionTitleBox'
import GreenBulletPoint from '../components/GreenBulletPoint'
import ProjectSection from '../components/ProjectSection'
import HowWeWork from '../components/HowWeWork'
import AboutusSections from '../components/AboutusSections'
import QuadrantView from '../components/QuadrantView'
import WhatDoYouNeed from '../components/WhatDoYouNeed'
import BudgetInteraction from '../components/BudgetInteraction'
import Footer from '../components/Footer'
import { Fab } from '@mui/material'
import { AddIcCallOutlined, ArrowUpward } from '@mui/icons-material'

const HomePage = () => {
    const aboutusRef = useRef(null)
    const servicesRef = useRef(null)
    const [showButton, setShowButton] = useState(false)
    const [isSticky, setIsSticky] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0)
    const [scrollDirection, setScrollDirection] = useState(null)
    const [scrollRoot, setScrollRoot] = useState(true)

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight
            const documentHeight = document.body.clientHeight
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop
            const currentPosition = window.pageYOffset
            setScrollDirection(currentPosition > scrollPosition ? 'down' : 'up')
            setScrollPosition(currentPosition)
            const heightDifference = documentHeight - windowHeight
            const scrolled = scrollTop / heightDifference
            setScrollRoot(scrolled < 0.1)
            if (scrolled == 0) {
                setIsSticky(false)
            } else if (scrolled > 0.1 && scrollDirection == 'up') {
                setIsSticky(true)
            } else {
                setIsSticky(false)
            }
            setShowButton(scrolled > 0.5)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [scrollPosition])

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <Header scrollRoot={scrollRoot} isSticky={isSticky} servicesRef={servicesRef} aboutRef={aboutusRef} />
            <SectionTitleBox
                sectionTitle={<span className="wideFont weDevelopAppsForText sectionTitle ">A dedicated bittensor contributing company</span>}
                width={12}
                title={
                    <div style={{ flexDirection: 'row', display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                        <span className="businessActivityText wideFont caps bold " style={{}}>
                            Mining
                        </span>
                        <GreenBulletPoint />
                        <span className="businessActivityText wideFont caps bold" style={{ textAlign: 'center' }}>
                            Training
                        </span>
                        <GreenBulletPoint />
                        <span className="businessActivityText wideFont caps bold " style={{ textAlign: 'center' }}>
                            Validating
                        </span>
                    </div>
                }
            />
            {/* <>
            <SectionTitleBox
                sectionTitle="our projects"
                title="case studies"
                marginBottom={30}
                hideBorder
                description="Consectetur quis mollit Lorem excepteur laborum.Amet est amet excepteur laborum.Amet est amet"
                />

            <ProjectSection left img={require('../assets/images/project1.png')} />
            <ProjectSection right img={require('../assets/images/project1.png')} />
            <ProjectSection left img={require('../assets/images/project1.png')} />
            <ProjectSection right img={require('../assets/images/project1.png')} />

                </> */}
            <SectionTitleBox sectionTitle="our workings" title="What do we do?" marginBottom={10} />

            <div ref={servicesRef} style={{ marginBottom: 40 }}>
                <HowWeWork
                    title="Subnets mining"
                    img={require('../assets/images/process1.png')}
                    description={`There are total 32 Subnets in bittensor currently, we are currently mining some of them and have an expertise in the same. We like to offer help to any Individual, company or a group of people in training and setting up their miner.`}
                />

                <HowWeWork
                    title="Providing mining Training"
                    img={require('../assets/images/process2.png')}
                    description={`We not just setup the miner for people, but instead focus on training them to be independent miners, and scale to all the SNs`}
                />

                <HowWeWork
                    title="Validating"
                    img={require('../assets/images/process3.png')}
                    description={
                        'We are in the process of running a validator named Sentinel on the bittensor network' +
                        ' any individual or group of people can reach out to us for validating guidance.'
                    }
                />

                <HowWeWork
                    title="& we offer perks"
                    img={require('../assets/images/process4.png')}
                    description={`If anyone stakes to our validator, we offer 1 miner/month as long as they keep staking including the miner maintenance ( as long as one keeps the stake to our validator )`}
                />
            </div>

            <div ref={aboutusRef}>
                <SectionTitleBox sectionTitle="about us" title="know our company" />
                <AboutusSections />
            </div>
            {/* <QuadrantView /> */}
            {/* <div style={{ marginTop: 100 }}>
                <WhatDoYouNeed />
            </div> */}
            <div >
                {/* <BudgetInteraction /> */}
                <Footer />
            </div>
            {showButton && (
                <div style={{ position: 'fixed', bottom: 30, right: 30 }}>
                    <Fab onClick={() => handleScrollToTop()} color="primary" aria-label="add">
                        <ArrowUpward color="secondary" />
                    </Fab>
                </div>
            )}
        </div>
    )
}

export default HomePage
